import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button, Content, CTABox, Spacer } from 'components';
export const _frontmatter = {
  "title": "Newsletter"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content mdxType="Content">
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<h1>Newsletter</h1>
	<p>
		Want to simplify your note-taking experience? Sign up for our MadNotes newsletter and get exclusive tips
		and tricks to make the most of our minimalistic markdown notepad. Our newsletter is perfect for anyone
		who wants to boost their productivity and streamline their workflow.
	</p>
	<p>
		As a subscriber, you'll get access to our latest updates, feature releases, and expert advice on how to use
		MadNotes to its fullest potential.Don't miss out on this opportunityto take your productivity to the next level
		– sign up for MadNotes newsletter today!
	</p>
	<iframe width="540" height="500" src="https://5afbfca6.sibforms.com/serve/MUIEAJVlIxyuHA5B0LkA25j0jrAtoKy41Pu25t6AIPbdCgPpcWX71gYGmUq1rIkjajuGfCYaSfNNiM3cPr2FlGk3zUQT5ZOkzqNdLiqD6inS-7DTcl-jZXy22QEszCioxxZjsGf2aADsF4j3JmWIu8mALKKcmPp1-3NMYHa5zMLcjJWD80evPCF43XQx7GQpg2AaenZuAGXqGr6Z" frameBorder="0" scrolling="no" allowFullScreen style={{
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "100%"
      }}></iframe>
    </Content>
    <Spacer height={60} responsive={{
      md: 40,
      sm: 30
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="Share your ideas and let us know you're waiting for the release!" heading="Shape up our product" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="https://github.com/BracketSpace/MadNotes/discussions" type="plain" mdxType="Button">
		Join GitHub Community
	</Button>
    </CTABox>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      